import React from "react";
import styled from "styled-components";

function Loading() {
  return (
    <LoadingContainer>
      <svg
        id="logo"
        width="804"
        height="120"
        viewBox="0 0 804 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="path-1-outside-1_201_24"
          maskUnits="userSpaceOnUse"
          x="0.0384521"
          y="0"
          width="804"
          height="120"
          fill="black"
        >
          <rect fill="white" x="0.0384521" width="804" height="120" />
          <path d="M42.9885 114.8C35.2885 114.8 28.5885 113.4 22.8885 110.6C17.1885 107.8 12.7885 103.9 9.68845 98.9C6.58845 93.9 5.03845 88.1 5.03845 81.5H18.2385C18.2385 85.6 19.1885 89.4 21.0885 92.9C22.9885 96.3 25.7385 99.05 29.3385 101.15C33.0385 103.15 37.5885 104.15 42.9885 104.15C50.0885 104.15 55.5885 102.45 59.4884 99.05C63.3884 95.65 65.3385 91.4 65.3385 86.3C65.3385 82.1 64.4385 78.75 62.6385 76.25C60.8385 73.65 58.3885 71.55 55.2885 69.95C52.2885 68.35 48.7885 66.95 44.7885 65.75C40.8885 64.55 36.7885 63.2 32.4885 61.7C24.3885 58.9 18.3885 55.45 14.4885 51.35C10.5885 47.15 8.63845 41.7 8.63845 35C8.53845 29.4 9.83845 24.45 12.5385 20.15C15.2385 15.75 19.0385 12.35 23.9385 9.95C28.9385 7.45 34.8385 6.2 41.6385 6.2C48.3385 6.2 54.1385 7.45 59.0385 9.95C64.0385 12.45 67.8885 15.9 70.5885 20.3C73.3885 24.7 74.8385 29.7 74.9385 35.3H61.7385C61.7385 32.4 60.9885 29.55 59.4884 26.75C57.9885 23.85 55.6885 21.5 52.5885 19.7C49.5885 17.9 45.7885 17 41.1885 17C35.4885 16.9 30.7885 18.35 27.0885 21.35C23.4885 24.35 21.6885 28.5 21.6885 33.8C21.6885 38.3 22.9385 41.75 25.4385 44.15C28.0385 46.55 31.6385 48.55 36.2385 50.15C40.8385 51.65 46.1385 53.4 52.1385 55.4C57.1385 57.2 61.6385 59.3 65.6385 61.7C69.6385 64.1 72.7385 67.2 74.9385 71C77.2385 74.8 78.3885 79.65 78.3885 85.55C78.3885 90.55 77.0885 95.3 74.4885 99.8C71.8885 104.2 67.9385 107.8 62.6385 110.6C57.4385 113.4 50.8885 114.8 42.9885 114.8Z" />
          <path d="M120.257 114.8C114.057 114.8 108.907 113.75 104.807 111.65C100.707 109.55 97.6572 106.75 95.6572 103.25C93.6572 99.75 92.6572 95.95 92.6572 91.85C92.6572 84.25 95.5572 78.4 101.357 74.3C107.157 70.2 115.057 68.15 125.057 68.15H145.157V67.25C145.157 60.75 143.457 55.85 140.057 52.55C136.657 49.15 132.107 47.45 126.407 47.45C121.507 47.45 117.257 48.7 113.657 51.2C110.157 53.6 107.957 57.15 107.057 61.85H94.1572C94.6572 56.45 96.4572 51.9 99.5572 48.2C102.757 44.5 106.707 41.7 111.407 39.8C116.107 37.8 121.107 36.8 126.407 36.8C136.807 36.8 144.607 39.6 149.807 45.2C155.107 50.7 157.757 58.05 157.757 67.25V113H146.507L145.757 99.65C143.657 103.85 140.557 107.45 136.457 110.45C132.457 113.35 127.057 114.8 120.257 114.8ZM122.207 104.15C127.007 104.15 131.107 102.9 134.507 100.4C138.007 97.9 140.657 94.65 142.457 90.65C144.257 86.65 145.157 82.45 145.157 78.05V77.9H126.107C118.707 77.9 113.457 79.2 110.357 81.8C107.357 84.3 105.857 87.45 105.857 91.25C105.857 95.15 107.257 98.3 110.057 100.7C112.957 103 117.007 104.15 122.207 104.15Z" />
          <path d="M176.66 113V38.6H188.06L188.96 49.4C191.36 45.4 194.56 42.3 198.56 40.1C202.56 37.9 207.06 36.8 212.06 36.8C217.96 36.8 223.01 38 227.21 40.4C231.51 42.8 234.81 46.45 237.11 51.35C239.71 46.85 243.26 43.3 247.76 40.7C252.36 38.1 257.31 36.8 262.61 36.8C271.51 36.8 278.61 39.5 283.91 44.9C289.21 50.2 291.86 58.4 291.86 69.5V113H279.41V70.85C279.41 63.15 277.86 57.35 274.76 53.45C271.66 49.55 267.21 47.6 261.41 47.6C255.41 47.6 250.41 49.95 246.41 54.65C242.51 59.25 240.56 65.85 240.56 74.45V113H227.96V70.85C227.96 63.15 226.41 57.35 223.31 53.45C220.21 49.55 215.76 47.6 209.96 47.6C204.06 47.6 199.11 49.95 195.11 54.65C191.21 59.25 189.26 65.85 189.26 74.45V113H176.66Z" />
          <path d="M337.989 114.8C329.189 114.8 322.189 112.15 316.989 106.85C311.789 101.45 309.189 93.2 309.189 82.1V38.6H321.789V80.75C321.789 96.25 328.139 104 340.839 104C347.339 104 352.689 101.7 356.889 97.1C361.189 92.4 363.339 85.75 363.339 77.15V38.6H375.939V113H364.539L363.639 99.65C361.339 104.35 357.889 108.05 353.289 110.75C348.789 113.45 343.689 114.8 337.989 114.8Z" />
          <path d="M430.129 114.8C423.029 114.8 416.729 113.2 411.229 110C405.729 106.7 401.379 102.15 398.179 96.35C395.079 90.55 393.529 83.7 393.529 75.8C393.529 68 395.079 61.2 398.179 55.4C401.279 49.5 405.579 44.95 411.079 41.75C416.679 38.45 423.129 36.8 430.429 36.8C437.629 36.8 443.829 38.45 449.029 41.75C454.329 44.95 458.379 49.2 461.179 54.5C463.979 59.8 465.379 65.5 465.379 71.6C465.379 72.7 465.329 73.8 465.229 74.9C465.229 76 465.229 77.25 465.229 78.65H405.979C406.279 84.35 407.579 89.1 409.879 92.9C412.279 96.6 415.229 99.4 418.729 101.3C422.329 103.2 426.129 104.15 430.129 104.15C435.329 104.15 439.679 102.95 443.179 100.55C446.679 98.15 449.229 94.9 450.829 90.8H463.279C461.279 97.7 457.429 103.45 451.729 108.05C446.129 112.55 438.929 114.8 430.129 114.8ZM430.129 47.45C424.129 47.45 418.779 49.3 414.079 53C409.479 56.6 406.829 61.9 406.129 68.9H452.929C452.629 62.2 450.329 56.95 446.029 53.15C441.729 49.35 436.429 47.45 430.129 47.45Z" />
          <path d="M482.079 113V5H494.679V113H482.079Z" />
          <path d="M588.779 114.8C581.379 114.8 574.879 113.1 569.279 109.7C563.779 106.3 559.479 101.65 556.379 95.75C553.379 89.85 551.879 83.15 551.879 75.65C551.879 68.15 553.429 61.5 556.529 55.7C559.629 49.8 563.929 45.2 569.429 41.9C574.929 38.5 581.429 36.8 588.929 36.8C595.029 36.8 600.429 38.05 605.129 40.55C609.829 43.05 613.479 46.55 616.079 51.05V5H628.679V113H617.279L616.079 100.7C613.679 104.3 610.229 107.55 605.729 110.45C601.229 113.35 595.579 114.8 588.779 114.8ZM590.129 103.85C595.129 103.85 599.529 102.7 603.329 100.4C607.229 98 610.229 94.7 612.329 90.5C614.529 86.3 615.629 81.4 615.629 75.8C615.629 70.2 614.529 65.3 612.329 61.1C610.229 56.9 607.229 53.65 603.329 51.35C599.529 48.95 595.129 47.75 590.129 47.75C585.229 47.75 580.829 48.95 576.929 51.35C573.129 53.65 570.129 56.9 567.929 61.1C565.829 65.3 564.779 70.2 564.779 75.8C564.779 81.4 565.829 86.3 567.929 90.5C570.129 94.7 573.129 98 576.929 100.4C580.829 102.7 585.229 103.85 590.129 103.85Z" />
          <path d="M682.668 114.8C675.568 114.8 669.268 113.2 663.768 110C658.268 106.7 653.918 102.15 650.718 96.35C647.618 90.55 646.068 83.7 646.068 75.8C646.068 68 647.618 61.2 650.718 55.4C653.818 49.5 658.118 44.95 663.618 41.75C669.218 38.45 675.668 36.8 682.968 36.8C690.168 36.8 696.368 38.45 701.568 41.75C706.868 44.95 710.918 49.2 713.718 54.5C716.518 59.8 717.918 65.5 717.918 71.6C717.918 72.7 717.868 73.8 717.768 74.9C717.768 76 717.768 77.25 717.768 78.65H658.518C658.818 84.35 660.118 89.1 662.418 92.9C664.818 96.6 667.768 99.4 671.268 101.3C674.868 103.2 678.668 104.15 682.668 104.15C687.868 104.15 692.218 102.95 695.718 100.55C699.218 98.15 701.768 94.9 703.368 90.8H715.818C713.818 97.7 709.968 103.45 704.268 108.05C698.668 112.55 691.468 114.8 682.668 114.8ZM682.668 47.45C676.668 47.45 671.318 49.3 666.618 53C662.018 56.6 659.368 61.9 658.668 68.9H705.468C705.168 62.2 702.868 56.95 698.568 53.15C694.268 49.35 688.968 47.45 682.668 47.45Z" />
          <path d="M753.139 113L724.939 38.6H738.139L760.489 100.85L782.989 38.6H795.889L767.689 113H753.139Z" />
        </mask>
        <path
          d="M42.9885 114.8C35.2885 114.8 28.5885 113.4 22.8885 110.6C17.1885 107.8 12.7885 103.9 9.68845 98.9C6.58845 93.9 5.03845 88.1 5.03845 81.5H18.2385C18.2385 85.6 19.1885 89.4 21.0885 92.9C22.9885 96.3 25.7385 99.05 29.3385 101.15C33.0385 103.15 37.5885 104.15 42.9885 104.15C50.0885 104.15 55.5885 102.45 59.4884 99.05C63.3884 95.65 65.3385 91.4 65.3385 86.3C65.3385 82.1 64.4385 78.75 62.6385 76.25C60.8385 73.65 58.3885 71.55 55.2885 69.95C52.2885 68.35 48.7885 66.95 44.7885 65.75C40.8885 64.55 36.7885 63.2 32.4885 61.7C24.3885 58.9 18.3885 55.45 14.4885 51.35C10.5885 47.15 8.63845 41.7 8.63845 35C8.53845 29.4 9.83845 24.45 12.5385 20.15C15.2385 15.75 19.0385 12.35 23.9385 9.95C28.9385 7.45 34.8385 6.2 41.6385 6.2C48.3385 6.2 54.1385 7.45 59.0385 9.95C64.0385 12.45 67.8885 15.9 70.5885 20.3C73.3885 24.7 74.8385 29.7 74.9385 35.3H61.7385C61.7385 32.4 60.9885 29.55 59.4884 26.75C57.9885 23.85 55.6885 21.5 52.5885 19.7C49.5885 17.9 45.7885 17 41.1885 17C35.4885 16.9 30.7885 18.35 27.0885 21.35C23.4885 24.35 21.6885 28.5 21.6885 33.8C21.6885 38.3 22.9385 41.75 25.4385 44.15C28.0385 46.55 31.6385 48.55 36.2385 50.15C40.8385 51.65 46.1385 53.4 52.1385 55.4C57.1385 57.2 61.6385 59.3 65.6385 61.7C69.6385 64.1 72.7385 67.2 74.9385 71C77.2385 74.8 78.3885 79.65 78.3885 85.55C78.3885 90.55 77.0885 95.3 74.4885 99.8C71.8885 104.2 67.9385 107.8 62.6385 110.6C57.4385 113.4 50.8885 114.8 42.9885 114.8Z"
          stroke="#54B689"
          strokeWidth="10"
          mask="url(#path-1-outside-1_201_24)"
        />
        <path
          d="M120.257 114.8C114.057 114.8 108.907 113.75 104.807 111.65C100.707 109.55 97.6572 106.75 95.6572 103.25C93.6572 99.75 92.6572 95.95 92.6572 91.85C92.6572 84.25 95.5572 78.4 101.357 74.3C107.157 70.2 115.057 68.15 125.057 68.15H145.157V67.25C145.157 60.75 143.457 55.85 140.057 52.55C136.657 49.15 132.107 47.45 126.407 47.45C121.507 47.45 117.257 48.7 113.657 51.2C110.157 53.6 107.957 57.15 107.057 61.85H94.1572C94.6572 56.45 96.4572 51.9 99.5572 48.2C102.757 44.5 106.707 41.7 111.407 39.8C116.107 37.8 121.107 36.8 126.407 36.8C136.807 36.8 144.607 39.6 149.807 45.2C155.107 50.7 157.757 58.05 157.757 67.25V113H146.507L145.757 99.65C143.657 103.85 140.557 107.45 136.457 110.45C132.457 113.35 127.057 114.8 120.257 114.8ZM122.207 104.15C127.007 104.15 131.107 102.9 134.507 100.4C138.007 97.9 140.657 94.65 142.457 90.65C144.257 86.65 145.157 82.45 145.157 78.05V77.9H126.107C118.707 77.9 113.457 79.2 110.357 81.8C107.357 84.3 105.857 87.45 105.857 91.25C105.857 95.15 107.257 98.3 110.057 100.7C112.957 103 117.007 104.15 122.207 104.15Z"
          stroke="#54B689"
          strokeWidth="10"
          mask="url(#path-1-outside-1_201_24)"
        />
        <path
          d="M176.66 113V38.6H188.06L188.96 49.4C191.36 45.4 194.56 42.3 198.56 40.1C202.56 37.9 207.06 36.8 212.06 36.8C217.96 36.8 223.01 38 227.21 40.4C231.51 42.8 234.81 46.45 237.11 51.35C239.71 46.85 243.26 43.3 247.76 40.7C252.36 38.1 257.31 36.8 262.61 36.8C271.51 36.8 278.61 39.5 283.91 44.9C289.21 50.2 291.86 58.4 291.86 69.5V113H279.41V70.85C279.41 63.15 277.86 57.35 274.76 53.45C271.66 49.55 267.21 47.6 261.41 47.6C255.41 47.6 250.41 49.95 246.41 54.65C242.51 59.25 240.56 65.85 240.56 74.45V113H227.96V70.85C227.96 63.15 226.41 57.35 223.31 53.45C220.21 49.55 215.76 47.6 209.96 47.6C204.06 47.6 199.11 49.95 195.11 54.65C191.21 59.25 189.26 65.85 189.26 74.45V113H176.66Z"
          stroke="#54B689"
          strokeWidth="10"
          mask="url(#path-1-outside-1_201_24)"
        />
        <path
          d="M337.989 114.8C329.189 114.8 322.189 112.15 316.989 106.85C311.789 101.45 309.189 93.2 309.189 82.1V38.6H321.789V80.75C321.789 96.25 328.139 104 340.839 104C347.339 104 352.689 101.7 356.889 97.1C361.189 92.4 363.339 85.75 363.339 77.15V38.6H375.939V113H364.539L363.639 99.65C361.339 104.35 357.889 108.05 353.289 110.75C348.789 113.45 343.689 114.8 337.989 114.8Z"
          stroke="#54B689"
          strokeWidth="10"
          mask="url(#path-1-outside-1_201_24)"
        />
        <path
          d="M430.129 114.8C423.029 114.8 416.729 113.2 411.229 110C405.729 106.7 401.379 102.15 398.179 96.35C395.079 90.55 393.529 83.7 393.529 75.8C393.529 68 395.079 61.2 398.179 55.4C401.279 49.5 405.579 44.95 411.079 41.75C416.679 38.45 423.129 36.8 430.429 36.8C437.629 36.8 443.829 38.45 449.029 41.75C454.329 44.95 458.379 49.2 461.179 54.5C463.979 59.8 465.379 65.5 465.379 71.6C465.379 72.7 465.329 73.8 465.229 74.9C465.229 76 465.229 77.25 465.229 78.65H405.979C406.279 84.35 407.579 89.1 409.879 92.9C412.279 96.6 415.229 99.4 418.729 101.3C422.329 103.2 426.129 104.15 430.129 104.15C435.329 104.15 439.679 102.95 443.179 100.55C446.679 98.15 449.229 94.9 450.829 90.8H463.279C461.279 97.7 457.429 103.45 451.729 108.05C446.129 112.55 438.929 114.8 430.129 114.8ZM430.129 47.45C424.129 47.45 418.779 49.3 414.079 53C409.479 56.6 406.829 61.9 406.129 68.9H452.929C452.629 62.2 450.329 56.95 446.029 53.15C441.729 49.35 436.429 47.45 430.129 47.45Z"
          stroke="#54B689"
          strokeWidth="10"
          mask="url(#path-1-outside-1_201_24)"
        />
        <path
          d="M482.079 113V5H494.679V113H482.079Z"
          stroke="#54B689"
          strokeWidth="10"
          mask="url(#path-1-outside-1_201_24)"
        />
        <path
          d="M588.779 114.8C581.379 114.8 574.879 113.1 569.279 109.7C563.779 106.3 559.479 101.65 556.379 95.75C553.379 89.85 551.879 83.15 551.879 75.65C551.879 68.15 553.429 61.5 556.529 55.7C559.629 49.8 563.929 45.2 569.429 41.9C574.929 38.5 581.429 36.8 588.929 36.8C595.029 36.8 600.429 38.05 605.129 40.55C609.829 43.05 613.479 46.55 616.079 51.05V5H628.679V113H617.279L616.079 100.7C613.679 104.3 610.229 107.55 605.729 110.45C601.229 113.35 595.579 114.8 588.779 114.8ZM590.129 103.85C595.129 103.85 599.529 102.7 603.329 100.4C607.229 98 610.229 94.7 612.329 90.5C614.529 86.3 615.629 81.4 615.629 75.8C615.629 70.2 614.529 65.3 612.329 61.1C610.229 56.9 607.229 53.65 603.329 51.35C599.529 48.95 595.129 47.75 590.129 47.75C585.229 47.75 580.829 48.95 576.929 51.35C573.129 53.65 570.129 56.9 567.929 61.1C565.829 65.3 564.779 70.2 564.779 75.8C564.779 81.4 565.829 86.3 567.929 90.5C570.129 94.7 573.129 98 576.929 100.4C580.829 102.7 585.229 103.85 590.129 103.85Z"
          stroke="#54B689"
          strokeWidth="10"
          mask="url(#path-1-outside-1_201_24)"
        />
        <path
          d="M682.668 114.8C675.568 114.8 669.268 113.2 663.768 110C658.268 106.7 653.918 102.15 650.718 96.35C647.618 90.55 646.068 83.7 646.068 75.8C646.068 68 647.618 61.2 650.718 55.4C653.818 49.5 658.118 44.95 663.618 41.75C669.218 38.45 675.668 36.8 682.968 36.8C690.168 36.8 696.368 38.45 701.568 41.75C706.868 44.95 710.918 49.2 713.718 54.5C716.518 59.8 717.918 65.5 717.918 71.6C717.918 72.7 717.868 73.8 717.768 74.9C717.768 76 717.768 77.25 717.768 78.65H658.518C658.818 84.35 660.118 89.1 662.418 92.9C664.818 96.6 667.768 99.4 671.268 101.3C674.868 103.2 678.668 104.15 682.668 104.15C687.868 104.15 692.218 102.95 695.718 100.55C699.218 98.15 701.768 94.9 703.368 90.8H715.818C713.818 97.7 709.968 103.45 704.268 108.05C698.668 112.55 691.468 114.8 682.668 114.8ZM682.668 47.45C676.668 47.45 671.318 49.3 666.618 53C662.018 56.6 659.368 61.9 658.668 68.9H705.468C705.168 62.2 702.868 56.95 698.568 53.15C694.268 49.35 688.968 47.45 682.668 47.45Z"
          stroke="#54B689"
          strokeWidth="10"
          mask="url(#path-1-outside-1_201_24)"
        />
        <path
          d="M753.139 113L724.939 38.6H738.139L760.489 100.85L782.989 38.6H795.889L767.689 113H753.139Z"
          stroke="#54B689"
          strokeWidth="10"
          mask="url(#path-1-outside-1_201_24)"
        />
      </svg>
    </LoadingContainer>
  );
}

export default Loading;

const LoadingContainer = styled.div`
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  background-color: #222831;
  #logo {
    width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    > path:nth-child(2) {
      stroke-dasharray: 638.1470947265625;
      stroke-dashoffset: 638.1470947265625;
      animation: line-anim 2s ease forwards;
    }
    > path:nth-child(3) {
      stroke-dasharray: 433.7718200683594;
      stroke-dashoffset: 433.7718200683594;
      animation: line-anim 2s ease forwards 0.01s;
    }
    > path:nth-child(4) {
      stroke-dasharray: 700;
      stroke-dashoffset: 700;
      animation: line-anim 2s ease forwards 0.013s;
    }
    > path:nth-child(5) {
      stroke-dasharray: 450;
      stroke-dashoffset: 450;
      animation: line-anim 2s ease forwards 0.015s;
    }
    > path:nth-child(6) {
      stroke-dasharray: 561.4005126953125;
      stroke-dashoffset: 561.4005126953125;
      animation: line-anim 2s ease forwards 0.017s;
    }
    > path:nth-child(7) {
      stroke-dasharray: 575.481201171875;
      stroke-dashoffset: 575.481201171875;
      animation: line-anim 2s ease forwards 0.019s;
    }
    > path:nth-child(8) {
      stroke-dasharray: 515.26416015625;
      stroke-dashoffset: 515.26416015625;
      animation: line-anim 2s ease forwards 0.021s;
    }
    > path:nth-child(9) {
      stroke-dasharray: 370.0504150390625;
      stroke-dashoffset: 370.0504150390625;
      animation: line-anim 2s ease forwards 0.023s;
    }
    > path:nth-child(10) {
      stroke-dasharray: 370.0504150390625;
      stroke-dashoffset: 370.0504150390625;
      animation: line-anim 2s ease forwards 0.023s;
    }
  }
  @keyframes line-anim {
    to {
      stroke-dashoffset: 0;
    }
  }
`;
